var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.didLoadData
    ? _c(
        "div",
        { staticClass: "nextcloud-widget nextcloud-phpopcache-wrapper" },
        [
          _c("div", { staticClass: "sep" }, [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.opcacheStartTimeTooltip(),
                    expression: "opcacheStartTimeTooltip()",
                  },
                ],
              },
              [
                _c("i", { staticClass: "fal fa-microchip" }),
                _c("strong", [_vm._v("PHP opcache")]),
                _vm._v("  "),
                _vm.opcache.opcache_enabled
                  ? _c("em", { staticClass: "oc-enabled" }, [
                      _vm._v(" " + _vm._s(_vm.tt("enabled")) + " "),
                    ])
                  : _c("em", { staticClass: "oc-disabled" }, [
                      _vm._v(_vm._s(_vm.tt("disabled"))),
                    ]),
                _vm._v("  "),
                _vm.opcache.cache_full
                  ? _c("strong", { staticClass: "oc-full" }, [
                      _c("i", { staticClass: "far fa-siren-on" }),
                      _vm._v(_vm._s(_vm.tt("cache-full")) + " "),
                    ])
                  : _vm._e(),
              ]
            ),
            _c("hr"),
            _vm.opcache.opcache_enabled
              ? _c("div", [
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.opcacheStatsTooltip(),
                          expression: "opcacheStatsTooltip()",
                        },
                      ],
                    },
                    [
                      _c("i", { staticClass: "fal fa-bullseye-arrow" }),
                      _c("em", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatNumber(_vm.opcache_stats.hits)
                          ),
                        },
                      }),
                      _vm._v("  "),
                      _c("small", [_vm._v(_vm._s(_vm.tt("hits")))]),
                      _vm._v("  "),
                      _c("em", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatNumber(_vm.opcache_stats.misses)
                          ),
                        },
                      }),
                      _vm._v("  "),
                      _c("small", [_vm._v(_vm._s(_vm.tt("misses")))]),
                      _vm._v("  "),
                      _c("em", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatPercent(
                              _vm.opcache_stats.opcache_hit_rate,
                              3
                            )
                          ),
                        },
                      }),
                      _vm._v("  "),
                      _c("small", [_vm._v(_vm._s(_vm.tt("hit-rate")))]),
                    ]
                  ),
                  _c("hr"),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.opcacheMemoryUsageTooltip(),
                          expression: "opcacheMemoryUsageTooltip()",
                        },
                      ],
                    },
                    [
                      _c("i", { staticClass: "fal fa-memory" }),
                      _c("em", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatPercent(
                              _vm.opcache.memory_usage.used_memory_percentage,
                              1
                            )
                          ),
                        },
                      }),
                      _vm._v("  "),
                      _c("small", [_vm._v("of")]),
                      _c("em", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.convertBytes(
                              _vm.opcache.memory_usage.total_memory
                            )
                          ),
                        },
                      }),
                      _vm._v("  "),
                      _c("small", [_vm._v(_vm._s(_vm.tt("memory-used")))]),
                    ]
                  ),
                  _c("hr"),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.opcacheInternedStringsTooltip(),
                          expression: "opcacheInternedStringsTooltip()",
                        },
                      ],
                    },
                    [
                      _c("i", { staticClass: "fal fa-puzzle-piece" }),
                      _c("em", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatNumber(
                              _vm.opcache.interned_strings_usage
                                .number_of_strings,
                              1,
                              true
                            )
                          ),
                        },
                      }),
                      _vm._v("  "),
                      _c("small", [_vm._v(_vm._s(_vm.tt("strings-use")))]),
                      _c("em", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatPercent(
                              _vm.opcache.interned_strings_usage
                                .used_memory_percentage
                            )
                          ),
                        },
                      }),
                      _vm._v("  "),
                      _c("small", [_vm._v(_vm._s(_vm.tt("of")))]),
                      _c("em", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.convertBytes(
                              _vm.opcache.interned_strings_usage.total_memory
                            )
                          ),
                        },
                      }),
                    ]
                  ),
                  _c("hr"),
                ])
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }